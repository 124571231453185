
import { defineComponent } from 'vue'
import ApiResponse from '@/types/api_response'

export default defineComponent({
  data () {
    return {
      showEmailVerificationMessage: false,
      emailResent: false,
      email: '',
      password: '',
      attemptedEmail: ''
    }
  },
  methods: {
    async login () {
      const token = await this.$recaptcha('login')
      this.$api.setRecaptchaToken(token)
      this.showEmailVerificationMessage = false
      this.attemptedEmail = this.email
      const response: ApiResponse = await this.$api.login(this.email, this.password)
      if (response.error && response.type === 'EmailNotVerified') {
        this.showEmailVerificationMessage = true
      }
    },
    async resendVerifyEmail () {
      const token = await this.$recaptcha('resend_verify_email')
      this.$api.setRecaptchaToken(token)
      const sent = await this.$api.verifyEmail(this.attemptedEmail)
      if (sent) {
        this.emailResent = true
      }
      this.$store.commit('success', { success: 'E-mail resent. If you don\'t see the verification in your inbox within 5 minutes, please call: ' + this.$api.getPhoneNumber() })
      this.$store.commit('dismissSuccess', { success: 'E-mail sent.' })
    },
    async forgotPassword () {
      const token = await this.$recaptcha('forgot_password')
      this.$api.setRecaptchaToken(token)
      this.$api.forgotPassword(this.attemptedEmail)
    }
  }
})
