<template>
<div class="container mt-3">
  <div class="mb-3 alert alert-info" v-if="showEmailVerificationMessage">
    <div>Check your E-mail inbox for a verification E-mail, follow the link in the E-mail to verify your account.</div>
    <div>If you don't see an E-mail from Motif Class:</div>
    <button class="mt-1 btn btn-primary" :disabled="emailResent" @click="resendVerifyEmail()">{{ !emailResent ? 'Re-send E-mail' : 'E-mail sent' }}</button>
  </div>
  <h4 class="mb-3">Log in</h4>
  <form @submit.prevent="login">
    <div class="row">
      <div class="col-lg mb-3">
        <label class="form-label" for="email">E-mail address</label>
        <input class="form-control" type="email" name="email" id="email" v-model="email" required>
      </div>
      <div class="col-lg mb-3">
        <label class="form-label" for="password">Password</label>
        <input class="form-control" type="password" name="password" id="password" v-model="password" required>
      </div>
    </div>
    <div class="row">
      <div class="col mb-3"><button class="btn btn-primary" type="submit">Log In</button></div>
      <div v-if="attemptedEmail" class="col mb-3"><a href="#" @click.prevent="forgotPassword()">Forgot password?</a></div>
    </div>
  </form>
  <hr>
  <div class="d-lg-flex"><div class="me-1">New to Motif Class (Local Classes)?</div> <router-link :to="{ name: 'register' }">Create an account!</router-link></div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ApiResponse from '@/types/api_response'

export default defineComponent({
  data () {
    return {
      showEmailVerificationMessage: false,
      emailResent: false,
      email: '',
      password: '',
      attemptedEmail: ''
    }
  },
  methods: {
    async login () {
      const token = await this.$recaptcha('login')
      this.$api.setRecaptchaToken(token)
      this.showEmailVerificationMessage = false
      this.attemptedEmail = this.email
      const response: ApiResponse = await this.$api.login(this.email, this.password)
      if (response.error && response.type === 'EmailNotVerified') {
        this.showEmailVerificationMessage = true
      }
    },
    async resendVerifyEmail () {
      const token = await this.$recaptcha('resend_verify_email')
      this.$api.setRecaptchaToken(token)
      const sent = await this.$api.verifyEmail(this.attemptedEmail)
      if (sent) {
        this.emailResent = true
      }
      this.$store.commit('success', { success: 'E-mail resent. If you don\'t see the verification in your inbox within 5 minutes, please call: ' + this.$api.getPhoneNumber() })
      this.$store.commit('dismissSuccess', { success: 'E-mail sent.' })
    },
    async forgotPassword () {
      const token = await this.$recaptcha('forgot_password')
      this.$api.setRecaptchaToken(token)
      this.$api.forgotPassword(this.attemptedEmail)
    }
  }
})
</script>
